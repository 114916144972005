.dataset-selection-container {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin: 0;
  }

  .datasets-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow-y: auto;

    .dataset-cards-container{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      border-radius: 15px;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
      background-color: #f2f6fc;
      
      .dataset-cards-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  
        height: auto;
        padding: 5px;
                
        .dataset-card {
          display: flex;
          gap: 10px;
          overflow-x: auto;
          padding: 5px 15px;
          background-color: #ffffff; 
          z-index: 10;
          min-height: 80px;

          border-radius: 15px;
          background-color: #ffffff; 
          box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

          &.selected{
            background-color: #e0e0e0;
            background-color: #d3e3fd;
          }

          &:hover {
            transform: translateY(-1px);
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          }
      
          .card-fields-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
      
            .card-field {
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              margin: 0;
      
              &:hover {
                cursor: pointer;
              }
            }
      
            .card-title {
              font-weight: 500;
            }
      
            .card-date {
              color:rgb(176, 176, 176);
              font-size: 12px;
            }
          }
      
          .card-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
      
            .card-icon {
              max-width: 40px;
            }
          }
        }
      }  
    }
  }

  .loading-indicator {
    font-size: 20px;
    position: relative;

    .spinner {
      border: 6px solid rgba(0, 0, 0, 0.1);
      border-left: 6px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }  
  }
}
