.dashboard-side-bar {      
  width: 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #f0f4f9; */
  /* box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12); */
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset;  */
  z-index: 15;

  & ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 0;

    .side-bar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 2px;
      
      /* Apply border radius only on the right side */
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      transition: all 0.3s ease;
      color: #333;
      text-decoration: none;
      box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  
      &:hover {
        background-color: #E0F7FA;
        cursor: pointer;
      }
  
      &.selected {
        background-color: #d3e3fd;
        font-weight: 600;
        color: #005662;
      }
  
      & img {
        max-width: 24px;
      }
  
      & .bar-item-name {
        font-size: 12px;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}
