
.dataset-cards-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  .dataset-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));  
    align-items: start;
    gap: 5px;
    width: 100%;
    height: auto;
    
  
    & .dataset-card {
      display: flex;
      gap: 10px;
      overflow-x: auto;
      padding: 15px;
  
      & .card-fields-group {
        display: flex;
        flex-direction: column;
  
        & .card-field {
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          margin: 0;
  
          &:hover {
            cursor: pointer;
          }
        }
  
        & .card-title {
          font-weight: 500;
        }
  
        & .card-date {
          color:rgb(176, 176, 176);
          font-size: 12px;
        }
      }
  
      & .card-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
  
        .card-icon {
          max-width: 40px;
        }
      }
    }
  }  
}
