.assignments-modal {
  width: 70vw;
  height: 90vh;
  min-width: 500px;

  .assignments-modal-body {   
    display: flex;
    flex-direction: column;
    height: 100%;

    .data-chunks-container{
      display: flex;
      flex-direction: column;

      .data-chunks-list{
        overflow-y: auto;
        border-radius: 10px;
        background-color: #f2f6fc;
        box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 4px inset;
        padding: 5px;
        gap: 4px;
        display: flex;

        .data-chunk-item{
          display: flex;
          align-items: center;
          justify-content: center;

          min-width: 75px;
          height: 25px;

          box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
          background-color:white;
          border-radius: 5px;

          &:hover{
            cursor: pointer;
            background-color: rgba(237, 253, 251, 0.5);
          }

          &.selected {
            background-color: #021210;
            color: white;
          }
        }


      }
    }

    .assignments-container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .assignments-container-header{
        display: flex;
        justify-content: flex-start;
        padding: 10px 0px;

        .annotator-filters-section{
          display: flex;
          gap: 10px;

          .annotator-filter{
            font-size: 20px;
            font-weight: 500;
            background: none;
            border: none;
            color: #A3F5ED;

            &:hover{
              cursor: pointer;
              color: #7EF1E5;
            }

            &:focus{
              outline: none;
            }

            &.selected{
              color: black;

              &:hover{
                color: black;
              }
            }
          }

          .vertical-seperator{
            background-color: black;
            width:1px;
          }
        }
      }

      .assignments-container-body{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
        overflow-y: auto;
        

        .no-annotators-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 10px;
          background-color: #f2f6fc;
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 4px inset;

          padding: 5px;
          height: 100%;
          max-height: 100%;

          .na-icon{
            max-width: 90px;
          }

          .na-text{
            font-weight: 500;
            font-size: 18px;
          }
        }

        .annotators-list{
          overflow-y: auto;
          border-radius: 10px;
          background-color: #f2f6fc;
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 4px inset;
          padding: 10px;
          height: 100%;
          width: 100%;
          gap: 1px;
          display: flex;
          flex-direction: column;

          .annotator-card{
            box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
            background-color:white;
            display: flex;
            flex-direction: column;
            min-height: 120px;
            padding: 10px 20px;
            border-radius: 10px;

            &:hover {
              transform: translateY(-1px);
              box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            }

            .annotator-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              min-width: 300px;
              max-width: 300px;
              overflow: hidden;
              flex:1;

              img {
                  max-width: 38px;
              }
      
              .username {
                  font-weight: bold;
                  font-size: 18px;
                  text-wrap: nowrap;
                  cursor: default;
                  
              }
            }

            .assignment-btn{
              height: 25px;
              font-size: 12px;
              color:black;
              outline: 1px solid #C8F9F2;
              background-color: white;
              padding: 4px 10px;
              border-radius: 4px;
              margin: 0;
              border: none;

              &:hover {
                cursor:pointer;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
              }
            
              &:disabled {
                background-color: #e0e0e0e0;
                color: #666666;
                cursor: not-allowed;
              }
            
              &:focus {
                outline: none;
              }

              &.pending {
                background: linear-gradient(135deg, #043927, #022118);
                color: #f0f0f0;

                &:hover {
                  background: linear-gradient(135deg, #06573d, #043827);
                }
              }
            }
          }
        }

        .dc-selection-warning{
          background-color: #f2f6fc;
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 4px inset;
          width: 100%;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }
      }
    }
  }

  .assignments-modal-footer {  
    display: flex;
    justify-content: center;
    align-items: center;
  }
}