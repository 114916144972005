.models-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .model-cards-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 300px));  
        align-items: start;
        gap: 5px;
        width: 100%;
        height: 100%;

        .model-card {
            display: flex;
            overflow:hidden;
            position: relative;

            .card-options-container{
                position: absolute;
                right: 10px;
                top: 10px;
        
                display: flex;
                flex-direction: column;
                align-items: flex-end;
        
                .card-options-icon{
                    img{
                        max-width: 15px;

                        &:hover{
                            transform: scale(1.02);
                        }
                    }
                }
        
                .card-options-list{
                    list-style: none;
                    border-radius: 4px;
                    background-color: white;
                    border: 1px solid #C8F9F4;
                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                    padding: 5px;
                    font-size: 12px;
        
                    .list-item{
                        display: flex;
                        align-items: center;
                        padding: 2px 5px;
                        border-radius: 2px;
            
                        &:hover{
                            background-color: #C8F9F4;
                            cursor: pointer;
                        }
        
                        .list-item-icon{
                            max-width: 15px;
                        }
        
                        .list-item-text{
                            margin-left: 10px;
                        }
                    }
                }
            }

            .card-body{
                display: flex;
                gap: 10px;
                overflow-x: auto;

                .card-icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .card-icon {
                        max-width: 40px
                    }
                }

                .card-fields-group {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    overflow: hidden;
    
                    .card-field {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        overflow-y: auto;
                        overflow-x: hidden;
                        margin: 0;
                
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .modality-tasktype-group{
                        display: flex;
                        color:rgb(176, 176, 176);
                        font-size: 12px;
                        gap: 5px;
                    }
    
                    .card-title {
                        font-weight: 600;
                    }
    
                    .card-date {
                        color:rgb(176, 176, 176);
                        font-size: 14px;
                    }
                }
            }
        }
    }
}