.annotators-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
    box-sizing: border-box;
}

.annotators-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.annotator-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    min-height: 100px;
    overflow: hidden;
    /* max-height: 100px; */

    .info-row {
        display: flex !important;
        width: 100% !important;
        height: 100%;
        padding: 15px 20px;

        position: relative;
        justify-content: space-between;
        align-items: center !important;
        overflow-x: auto;
        box-sizing: border-box;
        overflow-y: hidden;

        .annotator-info-container {
            display: flex !important;
            align-items: center !important;
            /* min-width: 800px; */

            .user-avatar-and-username {
                display: flex !important;
                align-items: center !important;
                gap: 20px;
                min-width: 300px;
                max-width: 300px;
        
                img {
                    max-width: 35px;
                }
        
                .username {
                    font-weight: bold;
                    font-size: 20px;
                    text-wrap: nowrap;
                }
            }

            .user-type-container {
                min-width: 200px;
                max-width: 200px;
            }
        
            .user-projects-container {
                display: flex;
                flex-direction: column;

                min-width: 200px;
                max-width: 200px;
            
                .projects-list {
                    display: flex !important;
                    gap: 5px;
                    overflow-x: auto;
                    padding: 5px;
                    max-width: 300px;
                    box-sizing: border-box;
            
                    .list-item {
                        border-radius: 50%;
                        height: 30px;
                        aspect-ratio: 1/1;
                        display: flex !important;
                        color: white;
                        background-color: blue;
                        justify-content: center !important;
                        align-items: center !important;
                        z-index: 20;
                        transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            
                        &:hover {
                            cursor: pointer;
                            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .model-info-container{
                display: flex;
                flex-direction: column;
                min-width: 300px;
                max-width: 300px;

                .model-info{
                    display: flex;
                    gap: 5px;
                }
            }
        }

        .annotator-utils-container {
            border-radius: 12px;
            transition: opacity 0.2s ease-in-out;
    
            .util-buttons {
                display: flex;
                gap: 5px;
                height: 100%;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                
                &:hover {
                    box-shadow: none;
                }
    
                button {
                    background-color: #ffffff;
                    border: 1px solid #A3F5EA;      
                    cursor: pointer; 
                    border-radius: 6px;  
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    
                    &:hover {
                        background-color: #e0f7fa;
                    }
                }
    
                img {
                    max-width: 16px;
                }
            }
        }
    }

    .update-info-row {
        width: 100% !important;
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        padding: 5px 15px;
        border-top: 1px solid #A3F5EA;
        min-height: 50px;

        .update-btn-container {
            display: flex;
            align-items: center;
            gap: 5px;
            
            button {
                font-size: 10px;
                padding: 5px 10px;
                border-radius: 6px;
                cursor: pointer;
                transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
                z-index: 20;
    
                &:hover {
                    background-color: #f1f1f1;
                }
            }
    
            button:first-child {
                background-color: #f44336;
                color: white;
                border: none;
    
                &:hover {
                    background-color: #e53935;
                }
            }
    
            button:last-child {
                background-color: #4CAF50;
                color: white;
                border: none;
    
                &:hover {
                    background-color: #43A047;
                }
            } 
    
            button:disabled {
                background-color: #e0e0e0e0;
                color: #666666;
                cursor: not-allowed;
    
                &:hover {
                    background-color: #e0e0e0e0;
                }
            }
        }
    }
}
