.dashboard, .annotated-instances-dashboard{
  width:100%;
  height: 100%;

  padding: 5px 5px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 0;
  overflow: hidden;

  h2{
    font-size: 14px;
    color: rgb(32, 33, 36);
    /* font-weight: 600; */
    position: relative;
    padding: 4px;
  }

  h2::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: rgba(100, 121, 143, 0.12);
  }

  .project-cards-container, .dataset-cards-container, 
  .project-instances-container, .label-studio-container, .automatic-annotations-container,
  .annotated-instances-container, .annotators-container, .models-container{
    width: 100%;
    height: 100%;
    overflow: hidden;

    margin-top: 5px;
    padding: 15px;

    border-radius: 15px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
    
    background-color: #f2f6fc;

    z-index: 5;

    display: flex;
    position: relative;

    a{
      color: #fff;

      &:hover{
        color: #fff;
      }
    }

    .dataset-card, .project-card, .annotator-card, .model-card{
      border-radius: 15px;
      background-color: #ffffff; 
      box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      z-index: 10;

      &:hover {
        transform: translateY(-1px);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
    }

    .loading-indicator {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
  
      .spinner {
        border: 6px solid rgba(0, 0, 0, 0.1);
        border-left: 6px solid #3498db;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
        margin-right: 10px;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }  
    }

    .not-available-container{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 5px;
      height: 100%;
      max-height: 100%;

      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      
      .na-icon{
        max-width: 100px;
      }

      .na-text{
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  .no-projects-container, .no-datasets-container {
    width:100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 20px;
    color:#464646;
  }


  .message-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .message-icon{
      max-width: 90px;
      margin-bottom: 5px;
    }

    .empty-box-icon{
      animation: gentle-shake 0.5s ease-in-out;
      animation-iteration-count: 2;
      animation-delay: 0s; 
      animation-fill-mode: forwards;

      &:hover {
        animation-delay: 0s; /* Shake immediately on hover */
      }
    }

    .message-text, .message-text-main {
      font-size: 18px;
      text-align: center;
      margin: 5px 0;
      text-decoration: none;
    }
    
    .message-text-main {
      font-weight: bold;
      font-size: 20px;
      text-decoration: none;
      margin: 0;
    }
  }
}


::-webkit-scrollbar {
  width: 4px;              
  height: 4px;             
}

::-webkit-scrollbar-track {
  background: #f1f1f1;      
  border-radius: 12px;      
}

::-webkit-scrollbar-thumb {
  background: #888;        
  border-radius: 12px;     
}

::-webkit-scrollbar-thumb:hover {
  background: #555;        
}


@keyframes gentle-shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  50% { transform: translate(1px, -1px) rotate(0.5deg); }
  75% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}