/* Styling of Project Details page */
.project-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & .project-details-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  
    & label {
      font-weight: bold;
      margin: 0
    }
  
    & input, & textarea{
      width:50vw;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 6px 10px;
      margin-top: 3px;
    }
  
    & #project-description {
      height: 20vh;
      min-height: 20vh;
      max-height: 25vh;
    }
  }

  & h6 {
    margin: 0;
  }

}