.model-upload-modal {
    height: 90vh;
    width: 80vw;

    .models-modal-body {
        align-items: center;
        justify-content: center;

        .dockerizing-indicator{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            gap: 8px;
            
            img{
                margin: 0;
                max-width: 200px;
            }
        }
        .file-upload-section {
            display: flex;
            flex-direction: column;
            justify-content: center;  
            align-items: center;
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
            border: 1px solid #C8F9F2;
            border-radius: 15px;
            width: 100%;
            height: 100%;
            background-color: #f2f6fc;

            .file-upload-container {
                display: flex;
                flex-direction: column;
                height: auto;

                input[type="file"] {
                    display: none;
                }

                .file-input-label {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.3s ease;
                    display: flex;
                    flex-direction: column;
                    z-index: 9000000;
                    justify-content: center;
                    align-items: center;

                    img{
                        max-width: 90px;
                        width: 15vw;
                        margin-bottom: 10px;
                    
                        &:hover{
                            cursor: pointer;
                            transform: scale(1.02);
                        }
                    }
                }
            }
        }

        .upload-finalization-section{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .finalization-header{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .clear-selected-btn {
                    border: none;
                    color:#911c12;
                    background-color: white;
                    font-weight: 500;
                    
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
            
                    &:focus {
                        outline: none;
                    }
                }
            }

            .finalization-body{
                display: flex;
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                overflow-y: auto;

                .zip-info-container{
                    display: flex;
                    
                    .zip-img{
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                            max-height: 50px;
                        }
                    }
                    
                    .zip-info{
                        padding: 5px;

                        .zip-name{
                            font-weight: 600;
                        }

                        .zip-files-num{
                            font-size: 12px;
                        }
                    }
                }

                .list-container{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    overflow-y: auto;
                    padding: 10px 0px;
                    min-height: 250px;
                    border-radius: 10px;
                    /* box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12); */
                    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
                    background-color: #f2f6fc;

                    .columns-header{
                        display: flex;
                        width: 100%;
                        padding: 0px 33px;
    
                        .header-field{
                            flex: 1;
                            cursor: default;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
    
                    .compressed-items-list{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        flex: 1;
                        gap: 3px;
                        padding: 3px;
    
                        .list-item{
                            display: flex;
                            border-radius: 4px;
                            background-color:white;
                            box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);

                            padding: 16px 30px;
                            align-items: center;
    
                            max-height: 50px;
    
                            &:hover {
                                transform: translateY(-1px);
                                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                            }
                            
                            img {
                                cursor: pointer;
                            }
                            
                            .item-field{
                                flex: 1;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                cursor: default;
                                
                                .field-text{
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    padding-right: 40px;
                                    font-size: 14px;
                                    white-space: nowrap;
                                }
    
                                img{
                                    max-width: 25px;
                                }
                            }
                        }
                    }
                }

                .model-info-container {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    width: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .model-info-fields{
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .info-row{
                            padding: 10px;
                            display: flex;
                            gap: 15px;
                            width: 100%;
                            overflow-x: auto;

                            .labels-div {
                                flex: 1;
                                overflow-x: auto
                            }
                        }
                        
                        input{
                            padding: 6px 12px;
                            border-radius: 8px;
                            box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                            background-color: white;
                            cursor:text;
                            color: #444746;
                            border: none;
                            height:30px;
                        }
    
                        h6{
                            font-weight: 600;
                            font-size: 14px;
                        }
    
                        .dropdown{
                            position: relative;
                            display: inline-block;
                            height:30px;
                            
                            select{
                                border: none;
                                height: 100%;
                                padding: 6px 12px;
                                border-radius: 8px;
                                box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                                background-color: white;
                                cursor: pointer;
                                color: #444746;
                                border: none;


                                &:hover,
                                &:focus {
                                    background-color: #f0f4f9;
                                    outline: none;
                                }
                    
                                &:focus{
                                    outline: None;
                                    background-color: #C8F9F2;
                                    border-color: #C8F9F2;
                                }
                            }
                        }

                        .labels-container{
                            display: flex;
                            width: 100%;
                            align-items: center;
                            gap: 5px;
                            overflow: hidden;

                            label{
                                margin: 0px;
                                gap: 5px;
                                display: flex;
                                align-items: center;
                            }

                            .add-label-btn{
                                border-radius:50%;
                                width: 20px;
                                height:20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                                border: none;
                                background-color: white;
                                color: black;
                                margin-right: 4px;
                                
                                &:hover{
                                    cursor: pointer;
                                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                                }

                                &:disabled{
                                    background-color: white;
                                    cursor:default;
                                    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                                    color: #f2f6fc;
                                }
                            }

                            .import-file-txt {
                                color: black;
                        
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                                margin-left: 4px;
                        
                                &:hover {
                                    text-decoration: underline;
                                    cursor:pointer;
                                }
                            }

                            .labels-list{
                                display: flex;
                                flex: 1;
                                gap: 5px;
                                border-radius: 5px;
                                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
                                background-color: #f2f6fc;
                                padding: 8px 6px;
                                overflow-x: auto;
                                min-height: 30px;
                                margin-left: 4px;
                            }
                            
                            .label-item-container {
                                position: relative;
                                min-width: 80px;
                                border-radius: 5px;
                            
                                .label-item {
                                    background-color: white;
                                    border-radius: 5px;
                                    padding: 5px;
                                    min-width: 80px;
                                    text-align: center;
                                    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    height: 100%;
                                    
                                    &:hover {
                                        cursor: default;
                                        transform: translateY(-1px);
                                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                                    }
                                }
                            
                                .label-item-delete {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    transform: translate(50%, -50%);
                                    z-index: 20;
                                    font-size: 8px; 
                                    border-radius: 50%;
                                    width: 14px;
                                    height: 14px;
                                    display: none; /* Initially hidden */
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer; 
                                    color: black;
                                    /* background-color: white; */
                                    font-weight: 600;
                                    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                                    line-height: 1;
                                }
                                
                                /* Display the "x" button when hovering over the container */
                                &:hover .label-item-delete {
                                    display: flex;
                                }
                            
                                .label-item-delete:hover {
                                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                                }
                            }
                            
                            

                            
                        }
                    }
                }
            }
        }

    }

    & .models-modal-footer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}