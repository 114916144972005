/* Styling of Template Selection page */
.template-selection-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.modality-selection-section {
  flex: 2;
  display: flex;
  flex-direction: column;

  .modality-btn {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    width: 100px;
    height: 30px;
    padding: 4px 10px;
    background-color: white;
    border: 1px solid #e0e0e0e0;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      background-color: #E0F7FA;
      transform: scale(1.05);
    }

    &:focus {
      outline: none;
    }

    &.selected {
      background-color: rgb(32, 33, 36);
      color: white;
      border: none;
    }
  }
}

.vertical-separator-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .vertical-separator {
    width: 1px;
    height: 100%;
    background-color: #ccc;
  }
}

.template-selection-section {
  flex: 10;
  display: flex;
  flex-direction: column;

  .template-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 8px;
    padding: 5px 0px;

    max-height: 100%;
    overflow-y: auto;
  }

  .template-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    background-color: white;
    filter: grayscale(100%);
    border-radius: 10px;
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover,
    &.selected {
      cursor: pointer;
      filter: grayscale(0%);
      transform: translateY(-1px);
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 
                    0 1px 1px 0 rgba(0, 0, 0, 0.14), 
                    0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .img-container {
      flex: 1;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0%;
      }
    }

    .template-card-title {
      font-size: 14px;
      font-weight: 500;
      color: black;
      margin: 0;
      padding: 5px 15px;
      border-top: 1px solid #e0e0e0e0;
    }
  }

  .loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  
    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left: 4px solid #3498db;
      border-radius: 50%;
      width: 20px;
      aspect-ratio: 1/1;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }  
  }
}
