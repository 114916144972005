.import-annotations-modal{
    width: 80vw;
    height: 90vh;

    .import-annotations-modal-header{

    }

    .import-annotations-modal-body{

        .file-selection-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;

            .file-selection-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;      
        
                height: 100%;
                width: 100%;
        
                padding: 5px;
        
                border-radius: 15px;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
                background-color: #f2f6fc;
        
                overflow: hidden;
        
                label{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 20px;
                }
            
                img{
                    max-width: 100px;
                    width: 15vw;
                    margin-bottom: 20px;
            
                    &:hover{
                    cursor: pointer;
                    transform: scale(1.02);
                    }
                }
            }
        }

        .annotations-preview-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            overflow-y: hidden;

            .annotations-preview-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                padding: 5px 0px;

                .remove-instances-btn {
                    border: none;
                    color:#911c12;
                    background-color: transparent;
                    font-weight: 500;
                    
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
            
                    &:focus {
                        outline: none;
                    }
                }
            }

            .annotations-preview-header > div:first-child{
                display: flex;
                flex-direction: column;

                h6 {
                    margin: 0;
                }

                .json-info-container {
                    display: flex;

                    .file-img{
                        display: flex;
                        justify-content: center;

                        img{
                            max-width: 40px;
                        }
                    }
    
                    .json-info{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }


            .annotations-preview-body{
                height: 100%;
                width: 100%;
                border-radius: 15px;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
                background-color: #f2f6fc;
                padding: 20px;
                overflow-y: auto;
            }

            table {
                border-collapse: collapse;
                margin: 0;
                margin-bottom: 40px;
                width: 100%;
                box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
            }

            
            table th,
            table td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;
                min-width: 200px;
            }

            table th {
                background-color: #d3e3fd;
                font-weight: bold;
                max-width: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
            
                &:hover{
                    cursor: pointer;
                }
            }
            
            table tr{
                background-color: white;

                img{
                    max-width: 15px;
                    cursor: pointer;
                }
            }
            
            table tr:hover {
                background-color: #f9f9f9;
            }

            table .delete-column{
                background-color: #d3e3fd;
            }

            table tr:hover .delete-column{
                background-color: #d3e3fd;
            }

            .delete-annotation-btn {
                border: none;
                background-color: transparent;
                color: red;

                &:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .import-annotations-modal-footer{
        justify-content: center;
    }
}


