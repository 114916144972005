.utils-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p{
    margin: 0%;
  }

  .filters-section{
    display: flex;
    flex-direction: column;

    .filters-header{
      display: flex;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      gap: 5px;
      
      img{
        max-height: 14px;
      }
    }

    .filters{
      display: flex;
    }

    .dropdown {
      position: relative;
      display: inline-block;
    
      .dropdown-toggle {
        margin-left: 5px;
        padding: 6px 12px;
        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color: white;
        cursor: pointer;
        color: #444746;
    
        &:hover,
        &:focus {
          background-color: #f0f4f9;
          outline: none;
        }
      }
    
      .dropdown-menu {
        font-size: 14px;
        display: block;
        position: absolute;
        background-color: white;
        z-index: 30;
        white-space: nowrap;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 
                    0 1px 1px 0 rgba(0, 0, 0, 0.14), 
                    0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }
    
      .dropdown-item {
        cursor: pointer;
        color: #444746;
    
        &:hover {
          background-color: #E0F7FA;
        }
      }
    }
    

    .checkbox-dropdown{
      position: relative;
      display: inline-block;
      
      .dropdown-toggle {
        margin-left: 5px;
        padding: 6px 12px;
        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color: white;
        border: none;
        cursor: pointer;
        color: #444746;
    
        &:hover,
        &:focus {
          background-color: #f0f4f9;
          outline: none;
        }
      }

      .dropdown-menu {
        font-size: 14px;
        display: none;
        position: absolute;
        background-color: white;
        z-index: 15;
        white-space: nowrap;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 
                    0 1px 1px 0 rgba(0, 0, 0, 0.14), 
                    0 1px 3px 0 rgba(0, 0, 0, 0.12);
      }
      
      .dropdown-menu.open {
        display: block;
      }
      
      label {
        font-size: 14px;
        display: block;
        padding: 5px 15px;
        display: flex;
        gap: 4px;
        cursor: pointer;
        color: #444746;
        margin: 0px;
        
        &:hover {
          background-color: #E0F7FA;
        }
        
      }
    }
  }

  .utils-section{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
  }

  .util-searchbar {
    width: 25vw;
    border-radius: 3px;
    border: 1px solid #A3F5EA;
    font-size: 10px;
    padding: 5px 10px;
    height:100%;
  }

  .util-btn{
    height:100%;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color:black;
    background-color: #FDFFFC;
    border: 1px solid #A3F5EA;
    border-radius: 4px;
  
    &:hover {
      background-color: #DAFBF6;
      cursor:pointer;
    }
  
    &:focus {
      background-color: #DAFBF6;
      outline:none;
    }

    &:disabled{
      background-color: #e0e0e0e0;
      color: #666666;
      cursor: not-allowed;
    }

    .btn-icon {
      margin:0;
      border-radius: 0px;
      max-width: 25px;
    }
  }

  .create-project-btn, .upload-dataset-btn, .annotate-btn, .upload-model-btn, .create-annotator-btn{
    max-height: 35px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color:white;
    background-color: #E90C2D;

    font-size: 14px;
    padding: 8px 15px;
    border-radius: 6px;
    border: none;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      background-color: #C50720;
      /* transform: scale(1.025); */
    }
  
    &:focus{
      background-color: #C50720;
      outline:none;
    }

    &:disabled{
      background-color: #e0e0e0e0;
      color: #666666;
      cursor: not-allowed;
    }

    .create-btn-icon, .upload-btn-icon{
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        max-width: 20px;
      }
    }

    .create-btn-text, .upload-btn-text{
      margin: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  } 
}

.annotation-utils-bar{
  justify-content: end;
  align-items: center;
  gap: 5px;
}