.project-instances-container{
  display: flex;
  justify-content: center;
  align-items: center;

  .project-instances-list{
    display: flex;
    flex-direction: column; 
    gap: 2px;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-y: auto;
  
    .data-chunk-sublist {
      z-index: auto;
      display: flex;
      flex-direction: column;
      gap: 1px;
      align-items: center;
  
      .data-chunk-card {
        z-index: 15;

        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        min-height: 45px;
        height: 45px;
    
        overflow:hidden;
    
        padding: 0px 15px;
        margin-top: 2px;
    
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color: #d3e3fd;
        
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }

        .data-chunk-card-title {
          font-weight: bold;
          color: #021627;
        }
    
        .data-chunk-card-utils {
          display: flex;
          height: 30px;
    
          .annotators-btn, .stats-btn {
            background-color: white;
            aspect-ratio: 1/1;
            margin-right: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #A3F5EA;
            height: 100%;
            height: 100%;

            padding: 1px 2px;
            border-radius: 6px;
            cursor: pointer;
  
            img {
              max-width: 20px;
            }
  
            &:hover{
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
          }

          .view-all-btn{
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
            }
          
            .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }
            }
          }
    
          .expand-btn{
            display: flex;
            align-items: center;
      
            &:hover{
              cursor: pointer;
            }
      
            img{
              max-width: 20px;
            }
          }
        }
      }
  
      .instances-cards-header{
        display: flex;
        padding: 0px 10px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
  
        & .fields-section{
          flex: 10;
          display: flex;
  
          & .header-field{
            flex: 1;
          }
        }
  
        & .empty-section{
          display: flex !important;
          flex: 2;
        }
      }
  
      .instance-card {
        display: flex;
        align-items: center;
  
        width: 99%;
    
        min-height: 45px;
        height: 45px;
    
        overflow:hidden;
    
        padding: 0px 10px;
    
        border-radius: 10px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color:white;

        z-index: 15;

        &:hover {
          transform: translateY(-1px);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }
    
        .item-fields-section{
          flex: 10;
          display: flex !important;
          width: 100%;
          max-height: 100%;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          align-items: center !important;
          white-space: nowrap !important;
          
          .item-field{
            padding-right: 10px;
            flex:1;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            max-height: 100%;
          }
  
          .instance-card-title {
            font-weight: bold;
          }
        }
    
        .item-utils-section{
          flex: 2;
          display: flex;
          justify-content: flex-end;
          height: 30px;
    
          .stats-btn {
            background-color: white;
            border-radius: 4px;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;
  
            height: 100%;
            
            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            
            img {
              max-width: 20px;
            }
  
            &:hover{
              cursor: pointer;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
          }
  
          .view-btn {
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
            }
          
            .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}

.annotation-modules-container{
  display: flex;
  flex-direction: column; 
  width: 100%;
  height: 100%;

  .dropdown{
    position: relative;
    display: inline-block;
    height:30px;
    
    select{
        border: none;
        height: 100%;
        padding: 6px 12px;
        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color: white;
        cursor: pointer;
        color: #444746;
        border: none;


        &:hover,
        &:focus {
            background-color: #f0f4f9;
            outline: none;
        }

        &:focus{
            outline: None;
            background-color: #C8F9F2;
            border-color: #C8F9F2;
        }
    }
  }


  .annotation-modules-list{
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: auto;

    .repeat-annotations-btn{
      border: none;
      background-color: none;
      cursor: pointer;
      background-color: transparent;

      &:focus{
        outline: none;
      }

      &:hover{
        transform: translateY(-1px);
      }

      img{
        max-width: 15px;
      }
    }

    .data-chunk-container {
      z-index: auto;
      display: flex;
      flex-direction: column;
      gap: 1px;
      align-items: center;
      overflow-y: auto;
      width: 100%;  
  
      .data-chunk-card {
        z-index: 15;

        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        min-height: 45px;
        height: 45px;
    
        overflow:hidden;
    
        padding: 0px 15px;
        margin-top: 2px;
    
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color: #d3e3fd;
        
        &:hover {
          transform: translateY(-1px);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }

        .data-chunk-card-title {
          font-weight: bold;
          color: #021627;
        }
        
        .data-chunk-card-utils {
          display: flex;
          height: 30px;
          gap: 15px;

          .progress-container{
            display: flex;
            align-items: center;
            gap: 4px;

            .progress-bar{
              height: 10px;
              border-radius: 5px;
              width: 25vw;
              max-width: 200px;
              position: relative;
              background-color: white;
              border: 1px solid #C8F9F2;
              cursor: pointer;
            
              .completion-bar{
                position: absolute;
                height: 100%;
                background-color: #FFA21F;
                border-radius: 5px;
              }
            }
          }
          
    
          .annotators-btn, .stats-btn {
            background-color: white;
            aspect-ratio: 1/1;
            margin-right: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #A3F5EA;
            height: 100%;
            height: 100%;

            padding: 1px 2px;
            border-radius: 6px;
            cursor: pointer;
  
            img {
              max-width: 20px;
            }
  
            &:hover{
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
          }

          .view-all-btn{
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
            }
          
            .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }
            }
          }
    
          .expand-btn{
            display: flex;
            align-items: center;
      
            &:hover{
              cursor: pointer;
            }
      
            img{
              max-width: 20px;
            }
          }
        }
      }
    }

    .instances-list{
      width: 100%;

      .instances-cards-header{
        display: flex;
        padding: 0px 10px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
  
        & .fields-section{
          flex: 10;
          display: flex;
  
          & .header-field{
            flex: 1;
          }
        }
  
        & .empty-section{
          display: flex !important;
          flex: 2;
        }
      }
  
      .instance-card {  
        display: flex;
        align-items: center;
  
        width: 99%;
    
        min-height: 45px;
        height: 45px;
    
        overflow:hidden;
    
        padding: 0px 10px;
    
        border-radius: 10px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color:white;
  
        z-index: 15;
  
        &:hover {
          transform: translateY(-1px);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }
        
        .item-fields-section{
          flex: 10;
          display: flex !important;
          width: 100%;
          max-height: 100%;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          align-items: center !important;
          white-space: nowrap !important;
          
          .item-field{
            padding-right: 10px;
            flex:1;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            max-height: 100%;
          }
  
          .instance-card-title {
            font-weight: bold;
          }
        }
    
        .item-utils-section{
          flex: 2;
          display: flex;
          justify-content: flex-end;
          height: 30px;
          gap: 16px;
          
          .pending-annotation-checkbox{
            cursor: pointer;
          }

          .view-btn{
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
            }
          
            .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }

              &:focus {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}