.statistics-modal{
  width: 80vw;

  .stats-block {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    
    .stats-block-header {
      font-weight: 800;
      margin-bottom: 5px;
    }

    .stats-block-body {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .annotators-list{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 2px 5px;
    overflow-x: auto;

    .annotator-icon{
      border-radius: 100%;
      width: 30px;
      min-width:30px;
      height: 30px;
      display: flex;
      color: white;
      background-color: blue !important;
      justify-content: center;
      align-items: center;

      &:hover{
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .instance-statistics-content{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;

    .instance-name{
      font-weight: 800;
      font-size: 20px;
      margin-bottom: 20px;
    }
    
    .instance-stats{
      width: 100%;  
      gap:20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      
      .stats-header{
        font-weight: 800;
      }
    
      .instance-general-stats{
        display: flex;
        gap: 20px;
        overflow-y: auto;
        padding: 5px 10px;
        /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    
        .progress-stats{
          display:flex;
          flex-direction: column;
          gap: 5px;
  
          .instance-progress{
            .progress-bar{
              height: 10px;
              border-radius: 5px;
              width: 75%;
              position: relative;
              background-color: white;
              border: 1px solid #C8F9F2;
    
              .completion-bar{
                position: absolute;
                height: 100%;
                background-color: #FFA21F;
                border-radius: 5px;
              }
            }
          }
    
        }
  
        .time-stats{
          display:flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    
      .annotation-charts{
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        overflow: auto;
        border-radius: 5px;
        background-color: #EDFDFC;
        box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px inset;

        .annotation-chart{
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img{
            max-width: 90%;
            max-height: 90%;
            box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px;
  
            &:hover {
              cursor: pointer;
              transform: scale(1.01);
            }
          }
        }
  
        .chart-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 15px;
          padding: 10px;
  
          button{
            height: 30px;
            font-size: 12px;
            color:white;
            background-color: #E90C2D;
            padding: 4px 10px;
            border-radius: 4px;
            margin: 0;
            border: none;
          
            &:hover {
              background-color: #C50720;
              cursor:pointer;
            }
          
            &:disabled {
              background-color: #e0e0e0e0;
              color: #666666;
              cursor: not-allowed;
            }
          
            &:focus {
              outline: none;
            }
          
            &.hidden {
              visibility:hidden;
            }
          }
        } 
      }
    }  
  }

  .statistics-footer{
    justify-content: center;
  }
}