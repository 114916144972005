.annotator-creation-modal {
    height: 90vh;
    width: 70vw;

    .annotator-creation-modal-body {
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .annotator-details-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            h6{
                font-weight: 600;
                font-size: 14px;
            }

            .annotator-name-container{
                margin-top : 10px;
                margin-bottom : 20px;

                input{
                    padding: 4px;
                    border-radius: 3px;
                    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
                    background-color: white;
                    border: 1px solid #C8F9F2;
                    width: 20vw;
                    min-width: 200px;
                }
            }

            .models-list-container{
                height: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;

                .models-filtering-utils{
                    display: flex;
                    padding: 5px;
    
                    .dropdown{
                        select{
                            margin-left: 5px;
                            padding: 6px 12px;
                            border-radius: 8px;
                            box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                            background-color: white;
                            border: none;
                            cursor: pointer;
                            color: #444746;
                
                            &:hover,
                            &:focus {
                                background-color: #f0f4f9;
                                outline: none;
                            }
                        }
                    }
                }

                .models-list{
                    overflow-y: auto;
                    border-radius: 5px;
                    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
                    background-color: #f2f6fc;
                    padding: 5px;
                    height: 100%;
                    width: 100%;
                    gap: 1px;
                    display: flex;
                    flex-direction: column;
                    border-radius: 15px;

                    .model-card{
                        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
                        background-color: #ffffff; 
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 5vh;
                        min-height: 40px;
                        max-height: 60px;
                        padding: 5px 20px;
                        
                        &:hover {
                            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                        }
                        
                        img{
                            max-width: 30px;
                            margin-right: 5px;
                        }

                        .card-title {
                            font-weight: 600;
                        }

                        .select-model-btn{                            
                            height: 25px;
                            font-size: 12px;
                            color:black;
                            outline: 1px solid #C8F9F2;
                            background-color: white;
                            padding: 4px 10px;
                            border-radius: 4px;
                            margin: 0;
                            border: none;
            
                            &:hover {
                                cursor:pointer;
                                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                            }

                            &:disabled {
                                background-color: #e0e0e0e0;
                                color: #666666;
                                cursor: not-allowed;
                            }

                            &:focus {
                                outline: none;
                            }

                            &.selected {
                                background: linear-gradient(135deg, #043927, #022118);
                                color: #f0f0f0;
                                border-radius: 4px;
                                border: none;
                                cursor: pointer;

                                &:hover {
                                    background: linear-gradient(135deg, #06573d, #043827);
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .annotator-creation-footer{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}