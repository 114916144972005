.model-config-container {
    width: 55vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .model-config-section{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        
        .enable-assistance-switch-container {
            display: flex;
            align-items: center;
            font-family: Arial, sans-serif;
            font-size: 14px;
            border-radius: 4px;
            padding: 5px;
            margin-top: 20px;  

            .label {
                margin-right: 10px;
            }
        
            .switch {
                width: 40px;
                height: 20px;
                border-radius: 50px;
                background-color: #e0e0e0e0; /* Inactive background color */
                border: 1px solid #C8F9F2;
                position: relative;
                cursor: pointer;
                transition: background-color 0.3s;
            }
        
            .switch.active {
                background-color: lightgreen; /* Active background color */
            }
            
            .slider {
                width: 15px;
                height: 15px;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 2px;
                transform: translateY(-50%);
                transition: transform 0.3s, left 0.3s;
            }
            
            .switch.active .slider {
                left: 22px; 
            }
        }

        .models-list-header-txt{
            font-size: 16px;
            font-weight: 600;
        }

        .models-list{
            overflow-y: auto;
            /* border: 1px solid #C8F9F2; */
            border-radius: 15px;
            /* background-color: rgba(237, 253, 251, 0.5); */
            background-color: #E0F7FA;
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px inset; 
            padding: 5px;
            height: 100%;
            width: 100%;
            gap: 1px;
            display: flex;
            flex-direction: column;
            position: relative;

            .models-list-overlay{
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.5);
                z-index: 30;
            }

            .model-card{
                box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
                background-color:white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                padding: 30px 20px;
                border-radius: 10px;
                
                &:hover {
                    /* transform: translateY(-1px); */
                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 1px;
                }
                
                img{
                    max-width: 30px;
                    margin-right: 5px;
                }

                .card-title {
                    font-weight: 600;
                }

                .select-model-btn{
                    height: 25px;
                    font-size: 12px;
                    color:black;
                    outline: 1px solid #C8F9F2;
                    background-color: white;
                    padding: 4px 10px;
                    border-radius: 4px;
                    margin: 0;
                    border: none;
    
                    &:hover {
                        cursor:pointer;
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                    }

                    &:disabled {
                        background-color: #e0e0e0e0;
                        color: #666666;
                        cursor: not-allowed;
                    }

                    &:focus {
                        outline: none;
                    }

                    &.selected {
                        background-color: rgb(32, 33, 36);
                        color: #f0f0f0;
                        border-radius: 4px;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            background: linear-gradient(135deg, #06573d, #043827);
                        }
                    }
                }
            }
        }
    }
}