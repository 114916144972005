.statistics-modal{
    
  .not-available-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5px;
    height: 100%;
    max-height: 100%;

    .na-icon{
      max-width: 70px;
    }

    .na-text{
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }
  }

  .loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    .spinner {
      border: 6px solid rgba(0, 0, 0, 0.1);
      border-left: 6px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }  
  }

  .ground-truth-section{
    display: flex;
    flex-direction: column;
    width: 100%;

    .ground-truth-header{
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 10px;
    }

    .ground-truth-bar{
      display: flex;
      align-items: center;
      gap: 10px;

      .dropdown{
        select{
          margin-left: 5px;
          padding: 5px;
          border-radius: 3px;
          box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
          background-color: white;
          border: 1px solid #C8F9F2;
          height: 100%;

          &:hover{
            cursor: pointer;
          }

          &:focus{
            outline: None;
            background-color: #C8F9F2;
            border-color: #C8F9F2;
          }
        }
      }

      .text-input{
        padding: 5px;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color: white;
        border: 1px solid #C8F9F2;
        height: 100%;
        margin-left: 5px;

        &:focus{
          outline: None;
        }
      }
    }

    #label-studio{
      width: 100%;
    }
  }

  .statistics-footer {
    display: flex;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    border-top: none;
  }
}