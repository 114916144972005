.project-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #C50720;

  .project-cards-grid {
    display: grid;
    grid-template-columns:  repeat(auto-fill, minmax(380px, 1fr));
    gap: 2px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-content: flex-start;

    .project-card {
      padding: 16px 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content:space-between;
      min-height: 200px;
      position: relative;

      .card-options-container{
        position: absolute;
        right: 16px;
        top: 16px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .card-options-icon{
          img{
            max-width: 15px;
  
            &:hover{
              transform: scale(1.07);
              cursor: pointer;
            }
          }
        }

        .card-options-list{
          list-style: none;
          border-radius: 4px;
          border: 1px solid #C8F9F4;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          padding: 5px;

          .list-item{
            display: flex;
            align-items: center;
            padding: 2px 5px;
            border-radius: 2px;

            &:hover{
              background-color: #C8F9F4;
              cursor: pointer;
            }

            .list-item-icon{
              max-width: 15px;
            }

            .list-item-text{
              margin-left: 10px;
            }
          }
        }
      }

      .project-details{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        
        .project-title-and-status{
          display: flex;
          align-items: center;

          .project-card-title {
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
          }

          .project-status {
            border-radius: 50%; 
            width: 10px;
            height: 10px;
            margin-left: 10px;
            display: inline-block;
            transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; 
        
            &:hover {
                transform: scale(1.07); 
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); 
            }
          }
        }
        
        
        .project-card-modality-and-type {
          display: flex;
          gap: 5px;
          font-size: 12px;
          margin-top: 5px;
        }

        .project-card-date {
          color:rgb(176, 176, 176);
          font-size: 12px;
        }
        
        .project-card-description {
          margin-top:0px;
          font-size: 12px;
          margin-top: 5px;
        }
      }
  
      .project-card-btn {
        font-size: 12px;
        background-color: #E90C2D;
        color: #fff;

        padding: 1px 2px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
      
        &:hover {
          background-color: #C50720;
        }

        .btn-link {
          display: flex;
          justify-content: center;
          padding: 4px 10px;
          color: #fff !important;

          &:hover {
            text-decoration: none;
            color: #fff !important;
          }
        }
      }
    }
  }
}
