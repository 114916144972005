/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  /* font-family: Arial, sans-serif !important; */
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  height: 100vh;
  width: 100vw;
  max-width: 100%;

  z-index: 0;
}

a, a:visited {
  color: #fff;
}

body {
  min-height: 100vh;
  color: rgb(32, 33, 36);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}