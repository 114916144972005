
/* Styling of Label Selection page */
.label-selection-container {
  min-width: 50vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 3vh;

  h5{
    margin: 0;
  }

  .label-utils-section {
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      margin: 0;
    }

    .label-utils {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      gap: 5px;

      #label-name-input{
        width:15vw;
        padding: 6px 12px;
        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color: white;
        cursor:text;
        color: #444746;
        border: none;
        height:30px;
      }

      button {
        font-size: 12px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        padding: 4px 10px;

        color: black;

        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        background-color: white;
        cursor: pointer;
        color: #444746;
        border: none;

        &:hover,
        &:focus {
            background-color: #f0f4f9;
            outline: none;
        }

        &:focus{
            outline: None;
            background-color: #C8F9F2;
            border-color: #C8F9F2;
        }

        img {
          max-width: 20px;
        }
      }

      .add-button {
        background-color: #b32214;
        color: white;
        border-radius: 8px;
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        border: none;
        margin-right: 4px;
        
        &:hover {
          background-color: #852118;
          color: white;
        }

        &:disabled {
          background-color: #DAFBF7;;
          color: #666666;
          cursor: not-allowed;
        }
      }

      .color-picker {
        position: absolute;
        top: 0;
        margin-top: 40px;
      }

      .import-file-txt {
        color: black;
        margin-left: 4px;

        align-items: center;
        justify-content: center;
        margin: 0;
        margin-left: 4px;

        &:hover {
          text-decoration: underline;
          cursor:pointer;
        }
      }
    }
  }
  
  .labels-list-section {
    width: 100%;
    min-height: 30vh;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    overflow-y: auto;

    border-radius: 15px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
    background-color: #f2f6fc;

    & .labels-list {
      display:flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 100%;
      padding: 5px;
      gap: 5px;

      & .labels-list-item{
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0px 10px;
        height: 5vh;

        border-radius: 10px;
        background-color: #ffffff; 
        box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

        &:hover {
          transform: translateY(-1px);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }

        & .label-info{
          display: flex;
          align-items: center;

          & span {
            max-width: 20vw;
            overflow-x:hidden;
          }

          & .label-color-box {
            width: 15px;
            height: 15px;
            background-color: green;
            margin-left: 10px;
          }
        }

        & .remove-label-button {
          border: none;
          color: #911c12;
          background-color: white;
          font-weight: 500;

          &:hover{
            text-decoration: underline;
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    & .no-labels-message {
      font-size: 16px;
      text-align: center;
      color: #444746;
    }
  }
}