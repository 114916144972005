.creation-modal {
  width: 80vw;
  height: 90vh;
  max-height: 2000px;
  min-width: 500px;
  
  .creation-body {
    display: flex;
    justify-content: center;
  }

  .creation-footer {  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}