.instance-automatic-annotations-dashboard {

    h2 {
        padding: 5px;
    }

    /* Container of annotation setup */
    .label-studio-container {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        justify-content: center;

        #label-studio {
            width: 100%;
            height: 100%;
        }
    }

    .task-completed-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100%;
        max-height: 100%;
        width: 100%;

        .task-completed-icon {
            max-width: 100px;
        }

        .task-completed-text {
            font-weight: 500;
            font-size: 18px;
        }

        .return-dashboard-link {
            color: rgb(136, 27, 17);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}