.background-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;

  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, .5);
}