.dataset-upload-modal {
  height: 85vh;
  width: 70vw;

    .datasets-modal-body {
      align-items: center;
      justify-content: center;

      .instances-selection-section {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        width: 100%;
        height: 90%;
  
        .instances-selection-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;      
  
          height: 100%;
          width: 100%;
  
          padding: 5px;
  
          border-radius: 15px;
          box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
          background-color: #f2f6fc;

          overflow: hidden;

          label{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 20px;
          }
        
          img{
            max-width: 100px;
            width: 15vw;
            margin-bottom: 20px;
      
            &:hover{
              cursor: pointer;
              transform: scale(1.02);
            }
          }
        }
      }

      .upload-finalization-section {
        width: 100%;
        height: 100%; 

        display: flex;
        flex-direction: column;

        gap: 10px;

        overflow: hidden;

        .selected-instances-container{
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: hidden;

          .selected-instances-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0px;

            h6 {
              margin: 0;
            }

            .remove-instances-btn {
              border: none;
              color:#911c12;
              background-color: white;
              font-weight: 500;
              
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
      
              &:focus {
                outline: none;
              }
            }
          }

          .selected-instances-list{
            display: flex;
            flex-direction: column;
            gap: 2px;
            padding: 5px;
            
            border-radius: 5px;
            border-radius: 15px;
            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 4px inset; 
            background-color: #f2f6fc;

            overflow-y: auto;

            .instances-list-header {
              display: flex; 
              padding: 10px 30px;
              gap: 5px;
              min-width: 500px;

              .header-field{
                flex: 1;

                font-weight: 600;
                font-size: 12px;

                display: flex;
                justify-content: flex-start;
              }
            }

            .instances-list-item {
              min-width: 500px;
              min-height: 50px;

              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 5px;
    
              border-radius: 10px;
              background-color: #ffffff; 
              box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
              transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

              padding: 16px 30px;
      
              &:hover {
                transform: translateY(-1px);
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
              }

              & .instance-card-field {
                flex: 1;
                overflow: hidden;
                
                .field-text{
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                img{
                  max-width: 30px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        & .folder-name-container {
          width: 100%;

          display: flex;
          flex-direction: column;
          
          & h6 {
            margin: 0;
            padding: 5px 0px;
          }

          & input{
            width:30%;
            border: 1px solid #A3F5EA;
            border-radius: 4px;
            padding: 3px 10px;
          }

          & img{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
            max-width: 20px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    & .datasets-modal-footer{
      display: flex;
      justify-content: center;
      align-items: center;
    }
}


.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}