p {
  margin: 0;
}

a, a:visited {
  color: #fff;
}

header {
  font-family: Montserrat, sans-serif;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  top: 0;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  color: black;
  /* background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3) 80%, rgba(255, 255, 255, 0) 100%); */
  background-color: white;
  z-index: 20;

  .logo-icon {
    max-width: 40px;
  }

  .navigation-options {
    font-size: 17px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left:50%;
    transform: translate(-50%);
    text-decoration: none;
    color: #929292;
    font-weight: 500;
  
    div {
      color: #929292;
      text-decoration: none;

      &:hover {
        color: #E90C2D; 
        cursor: pointer;
      }
    }
  
    .active {
      color: #D50B2A;
    }
  
    .navigation-separator{
      margin: 0;
    }
  }

  .user-icon {
    max-width: 35px;
  
    &:hover {
      cursor: pointer;
    }
  }

  .profile-dropdown-wrap{
    position: absolute;
    top: 80%;
    right: 2%;
  
    width: 200px;
    max-height: 400px;
    border-radius: 5px;
    padding:10px;
  
    font-size: 14px;
  
    background-color: #021210;

    z-index: 25;
  
    .profile-dropdown-user-info{
      display: flex;
      align-items: center;
      color: #fff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
    
      & h4{
        color: white;
        font-size: 16px;
        margin:0;
      }
  
      & .profile-dropdown-img{
        width: 4vw;
        max-width: 36px;
        margin-right: 14px
      }
    }
  
    & .profile-dropdown-separator{
      width:100%;
      height:0.05vh;
      background-color: #fff;
      margin-bottom: 15px;
    }
  
    & .profile-dropdown-link{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      align-items: center;
  
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
  
      text-decoration: none;
      color: #fff;
  
      & span{
        transition: transform 0.5s;
      }
  
      &:hover span{
        transform: translateX(5px);
      }
  
      &:hover{
        background-color: #262222;
        cursor: pointer;
      }
  
      .link-text{
        align-items: center;
        display: flex;
  
        img{
          max-width: 17px;
          border-radius: 0px;
        }
  
        span{
          margin-left: 10px;
        }
      }
    }
  }
}